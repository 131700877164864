.ant-form-item-control{
    min-height: 54px !important;
}

.ant-form-item {
    margin-bottom: 0px !important;
}

.ant-drawer-inline {
    position: fixed !important;
}

::-webkit-scrollbar {
    margin-right: 0.1em;
    width: 16px;
    background-color: transparent;
}    
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    background-color: transparent;
    border-radius: 10px
}  
::-webkit-scrollbar-thumb {
    border: 5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #D3D3D3;
}
::-webkit-scrollbar-thumb:hover {
    background-color: #919191;
}